import React, { FC } from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  Link as _Link,
  useColorMode,
} from "@chakra-ui/react";
import Link from "next/link";
import IProject from "types/project";
import { IoMdArrowRoundForward } from "react-icons/io";

const Services: FC = () => {
  const { colorMode } = useColorMode();
  const cardBgColor = { light: "white", dark: "gray.900" };
  const cardColor = { light: "gray.900", dark: "white" };
  const services = [
    { title: "Web Application Development", description: "We use modern languages, frameworks, libraries such as React, Express, Next, Node, Angular, MongoDB, MySQL, PHP, JavaScript, TypeScript, HTML5, CSS3, SCSS... Also, we develop website based on CMS such as WordPress, Shopify, Wix." },
    { title: "Mobile Application Development", description: "We develop mobile applications in both Native Platform and Cross Platform. Languages and frameworks are Kotlin, Java, Swift, Objective-C, React Native and Flutter." }
  ];

  const headingNode = () => {
    return (
      <Box pb={4} d="flex" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="xl">
          Our Services
        </Heading>
      </Box>
    );
  };

  const titleNode = (title: string) => {
    return (
      <Heading as="h3" size="md">
        {title}
      </Heading>
    );
  };

  const descriptionNode = (description: string) => {
    return <Text fontSize="sm">{description}</Text>;
  };

  return (
    <Stack spacing={4}>
      {headingNode()}
      <Stack spacing={8}>
        {services.map((service, index: number) => {
          return (
            <Box
              key={index}
              bg={cardBgColor[colorMode]}
              color={cardColor[colorMode]}
              p={8}
              rounded="md"
              shadow="md"
            >
              <Stack spacing={4}>
                {titleNode(service.title)}
                {descriptionNode(service.description)}
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default Services;
